import React, {useRef, useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {PortfolioHeader, TabSection} from "../components/PortfolioPage/styles";
import PortfolioImage from "../images/portfolio_header_bg.svg"
import {Tab, Tabs} from "../components/Tabs";
import {PortfolioTabs} from "../components/Tabs/PortfolioTabs";
import {SectionTitle} from "../components/SectionTitle/styles";
import {colors} from "../const/colors";
// import {IoCheckmark} from "react-icons/io5";
import ImageAccordion, { AccordionList } from "../components/ImageAccordion";
import { FaQuestion } from "react-icons/fa"
import localData from "../localization/faq.json";
import { Link } from "gatsby"

const AccordionItems = {
    website: [
        {
            id:1,
            title: {
                en: "How long does it take to have my website on the web?",
                es: "¿Cuánto tiempo se tarda en crear mi sitio web?"
            },
            content: {
                en: "Website development timeline strongly depends on the website's complexity. Moreover, depending if you already have the website content ready or you will start making it together with the website, will determine how long the whole project will take. On average the website development can take from 2 to 8 weeks.",
                es: "La línea de tiempo de desarrollo del sitio web depende en gran medida de su complejidad. Además, dependiendo de si ya tienes el contenido del sitio web listo o comenzarás a hacerlo junto con el sitio web, determinará cuánto tiempo llevará todo el proyecto. En promedio, el desarrollo del sitio web puede llevar de 2 a 8 semanas."
            },
            icon: {
                name: <FaQuestion />
            },
            open: true
        },
        {
            id:2,
            title: {
                en: "How much does it cost to have my website developed?",
                es: "¿Cuánto cuesta desarrollar mi sitio web?"
            },
            content: {
                en: "Similarly, like for the website timeline, the cost strongly depends on the website's complexity. If you are looking for a presentational website that consists of up to 5 pages, the development price starts from 700€. On the other hand, if you are looking for a corporate website that consists of multiple departments and you would like to be able to manage it yourself together with your team, the average price is 1900€. <br><br> All Green Flamingo websites can be financed in monthly instalments",
                es: "Del mismo modo, al igual que para determinar la línea de tiempo del sitio web, el costo depende en gran medida de la complejidad. Si estás buscando un sitio web de presentación que consta de hasta 5 páginas, el precio de desarrollo comienza desde 700 €. Por otro lado, si estás buscando una web corporativa que consta de varios departamentos y te gustaría poder gestionarla tú mismo junto con tu equipo, el precio medio es de 1900 €.\n" +
                    "Todos los sitios web de Green Flamingo se pueden financiar en cuotas mensuales."
            },
            icon: {
                name: <FaQuestion />
            },
            open: false
        },
        {
            id:3,
            title: {
                en: "Can I change the content on my website?",
                es: "¿Puedo cambiar el contenido de mi sitio web?"
            },
            content: {
                en: `The website can be managed and updated by the Green Flamingo team (static), or it can be managed and updated by yourself and your team (dynamic). This depends on whether you want to integrate your website with our Content Management System <a href='/cms-the-berry'>\"THE BERRY\"</a>. <br><br> If you decide to start by creating a static website and later discover the need for frequent updates, you can easily upgrade to a dynamic website version. <br><br> All Green Flamingo websites are scalable and can be integrated with \\"The Berry\\" Content Management System at a later date.`,
                es: "El sitio web puede ser administrado y actualizado por el equipo de Green Flamingo (estático), o puede ser administrado y actualizado por ti mismo y tu equipo (dinámico). Esto depende de si deseas integrar tu sitio web con nuestro sistema de gestión de contenido <a href='/cms-the-berry'>\"The Berry\"</a>." +
                    "Si decides comenzar creando tu sitio web estático y luego descubres la necesidad de actualizaciones frecuentes, puedes actualizar fácilmente a una versión de sitio web dinámico\n"
            },
            icon: {
                name: <FaQuestion />
            },
            open: false
        },
        {
            id:4,
            title: {
                en: "Where the website is hosted?",
                es: "¿Dónde está alojado el sitio web?"
            },
            content: {
                en: "Websites are hosted on computers that you can access publicly. They are quite different than regular computers because their purpose is to store your website data and serve it in the most efficient way. You have a lot of different hosting providers available.<br><br> Website hosting is an important aspect to consider once creating your website. It impacts the loading speed, which is one of the most important factors that will determine the user experience and overall quality of your website. Moreover, it impacts your website's security. <br><br> For these reasons, we offer free hosting that comes with any Green Flamingo website and a small application to make sure that the server where your website is hosted is optimized and secured.",
                es: "Los sitios web están alojados en computadoras a las que puedes acceder públicamente. Son bastante diferentes a las computadoras normales porque su propósito es almacenar los datos de tu sitio web y servirlos de la manera más eficiente. Existen muchos proveedores de alojamiento diferentes disponibles.\n" +
                    "El alojamiento de sitios web es un aspecto importante a considerar al crear tu sitio web. Afecta la velocidad de carga, que es uno de los factores más importantes que determinarán la experiencia del usuario y la calidad general de tu sitio web. Además, afecta la seguridad. Por estas razones, ofrecemos alojamiento gratuito que viene con cualquier sitio web de Green Flamingo para asegurarnos de que el servidor donde está alojado tu sitio web esté optimizado y seguro."
            },
            icon: {
                name: <FaQuestion />
            },
            open: false
        },
        {
            id:5,
            title: {
                en: "When a website says not secure?",
                es: "¿Cuando un sitio web dice que no es seguro?"
            },
            content: {
                en: "When you encounter a “Not Secure” warning it is because the web page or website you are visiting is not providing an encrypted connection. When your browser connects to a website it can either use the HTTP (insecure) or HTTPS (secure). <br><br> We provide free SSL certificates for our clients, if you develop your website with us you do not need to worry about this. ",
                es: "Cuando encuentras una advertencia de \"No seguro\" es porque la página web o el sitio web que estás visitando no proporciona una conexión encriptada. Cuando tu navegador se conecta a un sitio web, puede usar HTTP (inseguro) o HTTPS (seguro).\n" +
                    "Proporcionamos certificados SSL gratuitos para nuestros clientes, si desarrollas tu sitio web con nosotros no necesitas preocuparte por esto."
            },
            icon: {
                name: <FaQuestion />
            },
            open: false
        },
        {
            id:6,
            title: {
                en: "Why website design is important?",
                es: "¿Por qué es importante el diseño de sitios web?"
            },
            content: {
                en: "Website design is important because it translates your company's message and should reflect your company's branding. User experience is an important factor that determines whether the website visitor will stay and explore your website, or leave. Your website is your place to showcase your business offer. Do not miss your chance to do it right! <br><br> Green Flamingo values website design and lets you lead the way towards creating the most adequate visual representation of your business, always providing the support of our experts.",
                es: "El diseño del sitio web es importante porque traduce el mensaje de tu empresa y debe reflejar la marca. La experiencia del usuario es un factor importante que determina si el visitante del sitio web se quedará y explorará tu sitio web o lo abandonará. Tu sitio web es tu lugar para mostrar tu oferta comercial. ¡No pierdas la oportunidad de hacerlo bien!\n" +
                    "Green Flamingo valora el diseño de sitios web y te permite liderar el camino hacia la creación de la representación visual más adecuada de tu negocio, siempre con el apoyo de nuestros expertos.\n"
            },
            icon: {
                name: <FaQuestion />
            },
            open: false
        }
    ],
    digitalMarketing: [
        {
            id:1,
            title: {
                en: "What content marketing is?",
                es: "¿Qué es el marketing de contenidos?"
            },
            content: {
                en:  "Quality content is the core element of all marketing efforts. Content marketing encompasses the creation and distribution of valuable, relevant, and consistent content. It helps companies communicate their message with their target audience, create brand awareness, and nurture relationships with clients. <br><br> Green Flamingo offers content creation as an optional component of web development projects, as a part of social media management, and also as a stand-alone service.",
                es: "El contenido de calidad es el elemento central de todos los esfuerzos de marketing. El marketing de contenidos abarca la creación y distribución de contenido valioso, relevante y coherente. Ayuda a las empresas a comunicar su mensaje a su público objetivo, crear conciencia de marca y fomentar las relaciones con los clientes."
            },
            icon: {
                name: <FaQuestion />
            },
            open: true
        },
        {
            id:2,
            title: {
                en: "How content marketing works?",
                es: "¿Cómo funciona el marketing de contenidos?"
            },
            content: {
                en: "In order to be successful with content marketing activities, a company should develop a solid content strategy. To do this, it is important to understand the target audience and their affinities. Once we know what the customers are looking for, we will better know how to provide it. The creation of buyer personas is an excellent method to gain a clear insight into the ideal customer profile for your business. Content marketing has a goal of attracting, engaging, and retaining the customers that are identified to form a part of your target audience.",
                es: "Para tener éxito con las actividades de marketing de contenido, una empresa debe desarrollar una estrategia de contenido sólida. Para hacer esto, es importante comprender al público objetivo y sus afinidades. Una vez que sepamos lo que buscan los clientes, sabremos mejor cómo proporcionárselo. La creación de personas compradoras es un método excelente para obtener una visión clara del perfil de cliente ideal para tu negocio. El marketing de contenidos tiene el objetivo de atraer, involucrar y retener a los clientes identificados para formar parte de tu público objetivo."
            },
            icon: {
                name: <FaQuestion />
            },
            open: false
        }
    ],
    branding: [
        {
            id:1,
            title: {
                en: "What are branding guidelines?",
                es: "¿Cuáles son las directrices de marca?"
            },
            content: {
                en: "Branding guidelines are a group of rules that your brand needs to have to achieve consistency, recognizability, and competitiveness. This digital document is developed at an early stage of the branding as a service together with a new logo or based on an existing one. Elements of branding guidelines are logo variations, color palette, typography selection, tagline, voice, and tone.",
                es: "Las directrices de marca son un grupo de reglas que tu marca necesita tener para lograr la consistencia, reconocible, y la competitividad. Este documento digital se desarrolla en una etapa temprana del branding como servicio junto con un nuevo logo o basado en uno existente. Los elementos de las directrices de la marca son las variaciones del logotipo, la paleta de colores, la selección de la tipografía, el eslogan, la voz y el tono."
            },
            icon: {
                name: <FaQuestion />
            },
            open: true
        },
        {
            id:2,
            title: {
                en: "What are branding elements?",
                es: "¿Cuáles son los elementos de la marca?"
            },
            content: {
                en: "Branding elements are print and digital materials used to visually communicate the brand's message within the company and with its customers. Examples of branding elements are business cards, internal documents, posters, leaflets, catalogues, loyalty cards, brochures, presentations, images, videos, infographics, animations, email templates, etc.",
                es: "Los elementos de marca son materiales impresos y digitales que se utilizan para comunicar visualmente el mensaje de la marca dentro de la empresa y con sus clientes. Ejemplos de elementos de marca son tarjetas de presentación, documentos internos, carteles, folletos, catálogos, tarjetas de fidelización, folletos, presentaciones, imágenes, videos, infografías, animaciones, plantillas de correo electrónico, etc."
            },
            icon: {
                name: <FaQuestion />
            },
            open: false
        },
        {
            id: 3,
            title: {
                en: "How does branding add value?",
                es: "¿Cómo el branding agrega valor a tu negocio?"
            },
            content: {
                en: "Branding adds value by consistently strengthening the visual communication of the brand within the company and with its customers by following structured guidelines through all print and digital materials.",
                es: "Branding agrega valor al fortalecer constantemente la comunicación visual de la marca dentro de la empresa y con sus clientes siguiendo pautas estructuradas a través de todos los materiales impresos y digitales."
            },
            icon: {
                name: <FaQuestion />
            },
            open: false
        },
        {
            id:4,
            title: {
                en: "Branding where to start?",
                es: "Branding, ¿por dónde empezar?"
            },
            content: {
                en: "The company's branding should start by creating a logo, afterwards, the guidelines are developed which will shape the visual identity of other branding elements. Branding is closely followed when creating the layout and visual elements for the website pages or social media strategies as well as any of the brand's print and digital materials.",
                es: "El Branding de la empresa debe comenzar con la creación de un logotipo. Luego, se desarrollan las directrices que darán forma a la identidad visual de otros elementos de la marca. Al crear el diseño y los elementos visuales para las páginas del sitio web o las estrategias de redes sociales, así como cualquiera de los materiales impresos y digitales, es importante incorporar el branding."
            },
            icon: {
                name: <FaQuestion />
            },
            open: false
        }
    ]
}

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

const IndexPage = ({data, location, pageContext}) => {

    const { lang } = pageContext
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)

  return <Layout location={location} lang="en" flags={flags}>
    <SEO
        title={ meta?.metaTitle || metaStatic.metaTitle}
        description={ meta?.metaDescription || metaStatic.metaDescription}
        keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
    />
      <PortfolioHeader title="FAQ" background={PortfolioImage} />
      {/*<div style={{position:"relative"}}  />*/}
      <TabSection style={{paddingBottom:"0"}}>
          <PortfolioTabs>
            <Tab label="website" title="Website">
                <div className="inside-wrapper" style={{background: "white"}}>
                    <div>
                        <ImageAccordion lang={lang} __html height="auto" accordionItems={AccordionItems.website} reactIcon withoutImage />
                    </div>
                </div>
                <div className="inside-wrapper" style={{background:colors.lightBg}}>
                    <div>
                        <SectionTitle align="center">Contact</SectionTitle>
                        <p style={{textAlign:"center"}}>If you have any questions about this Privacy Policy, please contact us by email: <br/>
                            <a style={{color:colors.greenColor, textDecoration:"none", fontWeight:"bold"}} href="">info@greenflamingobcn.com</a>
                        </p>
                    </div>
                </div>
            </Tab>
            <Tab label="digital-marketing" title="Digital Marketing">
               <div className="inside-wrapper" style={{background:"white"}}>
                    <div>
                        <ImageAccordion lang={lang} __html height="auto" reactIcon accordionItems={AccordionItems.digitalMarketing} withoutImage />
                    </div>
                </div>
                <div className="inside-wrapper" style={{background:colors.lightBg}}>
                    <div>
                        <SectionTitle align="center">Contact</SectionTitle>
                        <p style={{textAlign:"center"}}>If you have any questions about this Privacy Policy, please contact us by email: <br/>
                            <a style={{color:colors.greenColor, textDecoration:"none", fontWeight:"bold"}} href="">info@greenflamingobcn.com</a>
                        </p>
                    </div>
                </div>
            </Tab>
            <Tab label="branding" title="Branding">
                <div className="inside-wrapper" style={{background:"white"}}>
                   <div>
                       <ImageAccordion lang={lang} __html height="auto" reactIcon accordionItems={AccordionItems.branding} withoutImage />
                   </div>
                </div>
                <div className="inside-wrapper" style={{background:colors.lightBg}}>
                    <div>
                        <SectionTitle align="center">Contact</SectionTitle>
                        <p style={{textAlign:"center"}}>If you have any questions about this Privacy Policy, please contact us by email: <br/>
                            <a style={{color:colors.greenColor, textDecoration:"none", fontWeight:"bold"}} href="">info@greenflamingobcn.com</a>
                        </p>
                    </div>
                </div>
            </Tab>
          </PortfolioTabs>
      </TabSection>
  </Layout>
}

export default IndexPage
